<!--
 * @Author: wyq
 * @Date: 2021-08-31 15:14:51
 * @LastEditTime: 2021-10-27 10:03:15
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\knowledgeGraph.vue
-->
<template>
  <div class="knowledge-graph">
    <el-button type="primary" @click="showDialog">选择</el-button>
    <span v-for="item in showTags" :key="item.id" class="knowledge-tag">{{ item.label }}</span>
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <template v-if="changeType">
        <el-button type="primary" @click="checkStrictly = !checkStrictly">切换选择模式</el-button>
        当前为：{{ checkStrictly ? '单选' : '全选' }}
      </template>
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" size="mini"></el-input>
      <el-scrollbar style="height:400px">
        <el-tree
          ref="tree"
          :data="treeData"
          node-key="id"
          show-checkbox
          :check-strictly="checkStrictly"
          :default-checked-keys="confirmKey"
          :filter-node-method="filterNode"
        ></el-tree>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    grade: Number,
    subject: Number,
    labels: Array,
    changeType: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      dialogVisible: false,
      treeData: [],
      filterText: '',
      confirmNodes: [],
      confirmKey: [],
      checkStrictly: true
    }
  },
  computed: {
    currentInfo() {
      return {
        grade: this.grade,
        subject: this.subject,
      }
    },
    showTags() {
      let confirmNodes
      if (this.confirmNodes.length) {
        confirmNodes = this.confirmNodes.map((i) => {
          let label
          if (i.topLabel) {
            label = `${i.topLabel}: ${i.label}`
          } else if (i.topId) {
            let top = this.treeData.find((top) => top.id == i.topId)
            label = `${top.label}: ${i.label}`
          } else {
            label = i.label
          }
          return {
            label: label,
            id: i.id,
          }
        })
      } else {
        confirmNodes = []
      }
      return confirmNodes
    },
  },
  watch: {
    labels: {
      handler(val) {
        console.log(val)
        this.confirmNodes = val
        this.confirmKey = val.map((i) => i.id)
      },
      immediate: true,
    },
    currentInfo: {
      handler() {
        this.getKGTree()
      },
      immediate: true,
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    handleClose() {
      this.$refs.tree.setCheckedKeys([])
      this.dialogVisible = false
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    showDialog() {
      this.dialogVisible = true
    },
    getKGTree() {
      let params = {
        ...this.currentInfo,
        id: 0,
      }
      this.$service.getKGTree(params).then((res) => {
        this.treeData = res.tree
      })
    },
    submit() {
      this.confirmNodes = this.$refs.tree.getCheckedNodes()
      this.confirmKey = this.$refs.tree.getCheckedKeys()
      this.$emit('update:labels', this.confirmNodes)
      this.dialogVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.knowledge-graph {
  .el-button {
    margin-bottom: 8px;
    margin-right: 10px;
  }
  .knowledge-tag {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    max-width: 100%;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: #f7f7f7;
    color: #666666;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    vertical-align: middle;
  }
  .el-tree {
    margin: 20px 0;
  }
}
</style>