<!--
 * @Author: wyq
 * @Date: 2021-06-17 15:48:45
 * @LastEditTime: 2021-10-21 17:46:00
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\questionBank\bankItem.vue
-->
<template>
	<div class="bank-item">
		<div class="bank-item-left">
			<div ref="katex" :key="value.curQuestionId">
				<p style="white-space: pre-wrap">{{ currentQuestion.questionDetail.title }}</p>
				<template v-for="i in 5">
					<p
						:key="i"
						v-if="currentQuestion.questionDetail[`option${i}`]"
						style="white-space: pre-wrap"
					>
						{{ String.fromCharCode(64 + parseInt(i)) }}.{{
							currentQuestion.questionDetail[`option${i}`]
						}}
					</p>
				</template>
			</div>
		</div>
		<div v-if="value.questionSlice.length > 1" class="bank-item-right">
			<span>同源试题:</span>
			<ul>
				<li
					v-for="(item, index) in value.questionSlice"
					:key="item.questionTopType.id"
					:class="item.questionTopType.id == value.curQuestionId ? 'active' : ''"
					@click="changeQuestion(item.questionTopType.id)"
				>
					{{ index + 1 }}.{{ item.questionTopType.type | getQuestionType
					}}{{ item.questionTopType.id == value.motherQuestionId ? '(母题)' : '' }}
				</li>
			</ul>
		</div>
		<div class="bank-item-footer">
			<ul class="tag">
				<li>
					最近编辑时间：{{
						currentQuestion.questionTopType.updateTimeAt
							| datefmt('YYYY-MM-DD HH:mm:ss')
					}}
				</li>
				<li>推荐答题时间：{{ currentQuestion.questionDetail.ansSecond }}秒</li>
				<li>难度：{{ currentQuestion.questionDetail.difficultyLevel }}</li>
			</ul>
			<div>
				<el-button
					class="preview-btn"
					type="warning"
					size="small"
					@click="clickVideoPreview"
				>
					视频预览
				</el-button>
				<el-button class="preview-btn" type="warning" size="small" @click="clickPreview">
					预览
				</el-button>
				<el-button class="edit-btn" type="primary" size="small" @click="goEdit">
					编辑
				</el-button>
				<el-button class="delete-btn" type="danger" size="small" @click="clickDelete">
					删除
				</el-button>
			</div>
		</div>
		<div>
			<span
				v-for="item in currentQuestion.questionTopType.labelInfos"
				:key="item.id"
				class="knowledge-tag"
			>
				<template v-if="item.topLabel">{{ item.topLabel }}:</template>
				{{ item.label }}
			</span>
			<span v-for="item in progressLabels" :key="item.id" class="knowledge-tag">
				进度:{{ item.label }}
			</span>
		</div>
		<preview-dialog
			:visiable.sync="previewVisiable"
			:grade="grade"
			:subject="subject"
			:id="value.curQuestionId"
		></preview-dialog>
		<video-preview-dialog
			:visiable.sync="videoPreviewVisiable"
			:grade="grade"
			:subject="subject"
			:id="value.curQuestionId"
		></video-preview-dialog>
	</div>
</template>
<script>
import videoPreviewDialog from '../preview/videoPreviewDialog.vue'
export default {
  components:{
    videoPreviewDialog
  },
	props: {
		value: Object,
		grade: Number,
		subject: Number,
	},
	data() {
		return {
			previewVisiable: false,
			progressLabels: [],
			videoPreviewVisiable: false,
		}
	},
	mounted() {
		this.$formula(this.$refs['katex'])
	},
	watch: {
		'value.curQuestionId'() {
			let chapterLabels = this.currentQuestion.questionTopType.chapterLabels
				? this.currentQuestion.questionTopType.chapterLabels
				: []
			console.log(chapterLabels)
			this.progressLabels = chapterLabels.map((i) =>
				this.$store.state.progressTree.find((treeItem) => treeItem.id == i)
			)
			setTimeout(() => {
				this.$formula(this.$refs['katex'])
			}, 100)
		},
	},
	computed: {
		currentQuestion() {
			for (let i = 0; i < this.value.questionSlice.length; i++) {
				if (this.value.curQuestionId == this.value.questionSlice[i].questionTopType.id) {
					return this.value.questionSlice[i]
				}
			}
			return {}
		},
	},
	methods: {
		goEdit() {
			const { href } = this.$router.resolve({
				name: 'Detail',
				query: {
					grade: this.grade,
					subject: this.subject,
					id: this.value.curQuestionId,
				},
			})
			window.open(href, '_blank')
		},
		changeQuestion(val) {
			this.value.curQuestionId = val
		},
		clickDelete() {
			this.$confirm('是否确认删除试题？', '删除确认', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				cancelButtonClass: 'el-button--primary is-plain',
				confirmButtonClass: 'el-button--danger',
			})
				.then(() => {
					if (this.value.curQuestionId == this.value.motherQuestionId) {
						this.clickDeleteMotherQuestion()
					} else {
						this.deleteGroupQuestion()
					}
				})
				.catch(() => {})
		},
		clickDeleteMotherQuestion() {
			this.$confirm('删除母题会删除母题下的所有同源题， 确定删除吗？', '删除确认', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				cancelButtonClass: 'el-button--primary is-plain',
				confirmButtonClass: 'el-button--danger',
			})
				.then(() => {
					this.deleteQuestion()
				})
				.catch(() => {})
		},
		deleteQuestion() {
			let params = {
				grade: this.grade,
				subject: this.subject,
				questionId: this.value.curQuestionId,
			}
			this.$service.deleteQuestion(params).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!',
				})
				this.$emit('refresh')
			})
		},
		deleteGroupQuestion() {
			let params = {
				grade: this.grade,
				subject: this.subject,
				groupQuestionId: this.value.curQuestionId,
			}
			this.$service.deleteGroupQuestion(params).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!',
				})
				this.$emit('refresh')
			})
		},
		clickPreview() {
			this.previewVisiable = true
		},
		clickVideoPreview() {
			this.videoPreviewVisiable = true
		},
	},
}
</script>
<style lang="scss" scoped>
.bank-item {
	padding: 25px 0 48px;
	overflow: hidden;
	border-bottom: 1px solid #f0f0f0;
	&-left {
		float: left;
		width: calc(100% - 470px);
		margin-top: -1rem;
		min-height: 200px;
		font-size: 14px;
		line-height: 30px;
		letter-spacing: 1px;
	}
	&-right {
		float: right;
		span {
			font-family: PingFangSC-Normal;
			color: #333;
			font-size: 16px;
			vertical-align: top;
			line-height: 32px;
		}
		ul {
			display: inline-block;
			width: 378px;
			padding: 0;
			margin: 0;
			li {
				display: inline-block;
				width: 100px;
				height: 32px;
				margin-left: 24px;
				margin-bottom: 20px;
				background: #f4f6f8;
				border: 1px solid #f4f6f8;
				line-height: 32px;
				font-size: 14px;
				color: #666666;
				text-align: center;
				cursor: pointer;

				&.active {
					background: #e9f3f2;
					border: 1px solid #4e9c95;
					color: #4e9c95;
				}
			}
		}
	}
	&-footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		.tag {
			padding: 0;
			margin: 0;
			li {
				display: inline-block;
				height: 32px;
				padding: 0 12px;
				line-height: 32px;
				font-size: 14px;
				&:nth-of-type(1) {
					background-color: #e9f3f2;
					color: #4e9c95;
				}
				&:nth-of-type(2) {
					background-color: #d0dae7;
					color: #144686;
				}
				&:nth-of-type(3) {
					background-color: #f5d2d2;
					color: #cb1f1f;
				}
				& + li {
					margin-left: 20px;
				}
			}
		}
		.time {
			height: 32px;
			padding: 0 12px;
			line-height: 32px;
			background-color: #e9f3f2;
			color: #4e9c95;
			font-size: 14px;
		}
		.el-button {
			padding-left: 24px;
			padding-right: 24px;
			& + .el-button {
				margin-left: 32px;
			}
		}
	}
	.knowledge-tag {
		display: inline-block;
		height: 32px;
		line-height: 32px;
		padding: 0 16px;
		max-width: 100%;
		margin-top: 16px;
		margin-right: 8px;
		background-color: #f7f7f7;
		color: #666666;
		border-radius: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		box-sizing: border-box;
		vertical-align: middle;
	}
}
</style>
