<!--
 * @Author: wyq
 * @Date: 2021-06-16 11:15:40
 * @LastEditTime: 2021-10-21 16:03:25
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\questionBank.vue
-->
<template>
  <page-container class="page-question-bank" asideTitle="条件筛选">
    <el-button slot="asideTitle" type="text" @click="reset">重置</el-button>
    <search-bar
      slot="aside"
      ref="searchBar"
      :grade="grade"
      :subject="subject"
      @change="searchChange"
    ></search-bar>
    <content-container v-loading="loading">
      <div slot="title" class="title">
        检索出
        <span>{{total}}</span>
        道题
      </div>
      <bank-item
        v-for="item in bankItemList"
        :grade="grade"
        :subject="subject"
        :key="item.CurQuestionId"
        :value="item"
        @refresh="getQuestionList"
      ></bank-item>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="10"
        :current-page.sync="currentPage"
        :total="total"
        @current-change="getQuestionList"
        hide-on-single-page
      ></el-pagination>
    </content-container>
  </page-container>
</template>
<script>
import searchBar from '../components/questionBank/searchBar.vue'
import bankItem from '../components/questionBank/bankItem.vue'
export default {
  components: {
    searchBar,
    bankItem,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      form: {},
      bankItemList: [],
      loading: false,
    }
  },
  computed: {
    grade() {
      return Number(this.$route.query.grade)
    },
    subject() {
      return Number(this.$route.query.subject)
    },
  },
  mounted() {
    // this.getQuestionList()
  },
  methods: {
    searchChange(val) {
      this.form = val
      this.currentPage = 1
      this.getQuestionList()
    },
    getQuestionList() {
      this.loading = true
      let condition = {
        grade: this.grade, // 年级 1初中，2高中 不限为空
        subject: this.subject, // 学科 1 语文，2数学，3英语 不限为空
        minDifficultyLevel: 0.1,
        maxDifficultyLevel: 0.9, //难度
        minAnsSecond: 60,
        maxAnsSecond: 7200, //推荐回答时间
      }
      // 合并
      condition = {
        ...condition,
        ...this.form,
        labels: this.form.labelInfos
          ? this.form.labelInfos.map((i) => i.id)
          : [],
        knowledgePoints: this.form.version
          ? [this.form.version, ...this.form.knowledgePoints]
          : [],
      }
      let params = {
        pageNum: this.currentPage,
        condition,
      }
      this.$service
        .getQuestionList(params)
        .then((res) => {
          this.bankItemList = res.questions
          this.total = res.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    reset() {
      this.$refs.searchBar.initForm()
    },
  },
}
</script>
<style lang="scss" scoped>
.page-question-bank {
  .title {
    display: inline-block;
    span {
      color: #4e9c95;
    }
  }
}
</style>