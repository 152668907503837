<!--
 * @Author: wyq
 * @Date: 2021-06-16 16:58:56
 * @LastEditTime: 2021-10-21 16:03:15
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\questionBank\searchBar.vue
-->
<template>
  <div class="search-bar">
    <el-form ref="form" :model="form" label-width="140px" label-position="top" size="mini">
      <el-form-item label="所属题库">
        <el-select v-model.number="form.questionBank" placeholder="不限" @change="questionBankChange">
          <el-option
            v-for="item in $store.state.questionBankList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="divider"></div>
      <template v-if="form.questionBank == 1">
        <el-form-item label="教材版本" placeholder="不限">
          <el-select v-model="form.version" @change="versionChange">
            <el-option
              v-for="item in versionList"
              :key="'versionList' + item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="form.version">
          <el-form-item label="知识点分类">
            <el-cascader
              v-model="form.knowledgePoints"
              :options="knowledgePointsList"
              :props="cascaderProps"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="教材试题分类">
            <el-select v-model="form.classify" placeholder="不限">
              <el-option label="不限" :value="null"></el-option>
              <el-option
                v-for="item in classifyList"
                :key="'gradeList' + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </template>
      <template v-else-if="form.questionBank == 2">
        <el-form-item label="发布状态">
          <el-select v-model="form.releaseStatus" placeholder="未发布">
            <el-option label="不限" :value="null"></el-option>
            <el-option label="未发布" :value="3"></el-option>
            <el-option label="CAT测试题" :value="1"></el-option>
            <el-option label="CAT训练题" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否有标签">
          <el-select v-model="form.haveLabels" placeholder="不限">
            <el-option label="不限" :value="null"></el-option>
            <el-option label="有标签" :value="1"></el-option>
            <el-option label="没有标签" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.haveLabels == 1" label="知识点标签">
          <knowledge-graph
            :grade="grade"
            :subject="subject"
            :labels.sync="form.labelInfos"
            changeType
          ></knowledge-graph>
        </el-form-item>
        <el-form-item label="进度标签">
          <progress-label :grade="grade" :labels.sync="form.chapterLabels"></progress-label>
        </el-form-item>
      </template>
      <div class="divider"></div>

      <el-form-item label="题型">
        <el-select v-model="form.type" placeholder="不限">
          <el-option label="不限" :value="null"></el-option>
          <el-option
            v-for="item in $store.state.typeList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否有同源题">
        <el-select v-model="form.haveGroupQuestion" placeholder="不限">
          <el-option label="不限" :value="null"></el-option>
          <el-option label="有同源题" :value="1"></el-option>
          <el-option label="没有同源题" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否有图片">
        <el-select v-model="form.havePhoto" placeholder="不限">
          <el-option label="不限" :value="null"></el-option>
          <el-option label="有图片" :value="1"></el-option>
          <el-option label="没有图片" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select v-model="form.checkType" placeholder="不限">
          <el-option label="不限" :value="null"></el-option>
          <el-option label="新题" :value="1"></el-option>
          <el-option label="待审核" :value="4"></el-option>
          <el-option label="审核通过" :value="2"></el-option>
          <el-option label="审核未通过" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建者">
        <el-select v-model="form.userId" placeholder="不限">
          <el-option label="不限" :value="null"></el-option>
          <el-option
            v-for="item in userList"
            :key="'userList' + item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="divider"></div>

      <el-form-item label="难度" class="inline-style">
        <el-input-number
          v-model="temporary.minDifficultyLevel"
          controls-position="right"
          :min="0.1"
          :max="0.9"
          :step="0.1"
        ></el-input-number>
        <span>—</span>
        <el-input-number
          v-model="temporary.maxDifficultyLevel"
          controls-position="right"
          :min="0.1"
          :max="0.9"
          :step="0.1"
        ></el-input-number>
        <el-button type="primary" @click="submitDifficulty">确定</el-button>
      </el-form-item>
      <el-form-item label="推荐答题时间（秒）" class="inline-style">
        <el-input-number
          v-model="temporary.minAnsSecond"
          controls-position="right"
          :min="60"
          :max="7200"
        ></el-input-number>
        <span>—</span>
        <el-input-number
          v-model="temporary.maxAnsSecond"
          controls-position="right"
          :min="60"
          :max="7200"
        ></el-input-number>
        <el-button type="primary" @click="submitAnsSecond">确定</el-button>
      </el-form-item>
      <el-form-item label="题干包含关键字" class="key-word">
        <el-input v-model="temporary.keyWord" placeholder="请输入关键字"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="submitKeyword"></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import knowledgeGraph from '../edit/knowledgeGraph'
import progressLabel from '../edit/progressLabel'
export default {
  props: {
    grade: Number,
    subject: Number,
  },
  components: {
    knowledgeGraph,
    progressLabel,
  },
  data() {
    return {
      form: {},
      temporary: {
        minDifficultyLevel: 0.1,
        maxDifficultyLevel: 0.9, //难度
        minAnsSecond: 60,
        maxAnsSecond: 7200, //推荐回答时间
        keyWord: '',
      },
      versionList: [],
      knowledgePointsList: [],
      userList: [],
      cascaderProps: {
        value: 'id',
        label: 'label',
      },
      classifyList: [],
    }
  },
  computed: {
    userType() {
      return localStorage.getItem('userType')
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('change', newVal)
      },
      deep: true,
    },
    grade(val) {
      console.log('grade', val)
      this.initForm()
    },
  },
  methods: {
    initForm() {
      this.form = {
        questionBank: 2, //所属题库
        classify: null, //教材试题分类
        type: null, //题型,
        minDifficultyLevel: 0.1,
        maxDifficultyLevel: 0.9, //难度
        minAnsSecond: 60,
        maxAnsSecond: 7200, //推荐回答时间
        keyWord: '', //关键字
        checkType: null,
        userId: null,
        haveGroupQuestion: null,
        havePhoto: null,
        haveLabels: null,
        labelInfos: [],
        knowledgePoints: [],
        releaseStatus: null,
        chapterLabels: [],
      }
      this.temporary = {
        minDifficultyLevel: 0.1,
        maxDifficultyLevel: 0.9, //难度
        minAnsSecond: 60,
        maxAnsSecond: 7200, //推荐回答时间
        keyWord: '',
      }
      this.getVersionList()
    },
    submitDifficulty() {
      this.form.minDifficultyLevel = this.temporary.minDifficultyLevel
      this.form.maxDifficultyLevel = this.temporary.maxDifficultyLevel
    },
    submitAnsSecond() {
      this.form.minAnsSecond = this.temporary.minAnsSecond
      this.form.maxAnsSecond = this.temporary.maxAnsSecond
    },
    submitKeyword() {
      this.form.keyWord = this.temporary.keyWord
      console.log(this.form.keyWord)
    },
    // 所属题库更改
    questionBankChange(val) {
      if (val == 1) {
        this.form.haveLabels = null
        this.form.labelInfos = []
        this.form.releaseStatus = null
        this.form.chapterLabels = []
      } else if (val == 2) {
        // 清空知识点分类
        this.form.version = null
        this.form.knowledgePoints = []
        this.knowledgePointsList = []
        this.form.classify = null
        this.classifyList = []
      }
    },
    // 获取用户列表
    getUserList() {
      this.$service.getUserList().then((res) => {
        this.userList = res.userList
      })
    },
    // 获取教材版本列表
    getVersionList() {
      let params = {
        grade: this.grade,
        subject: this.subject,
      }
      this.$service.getKPList(params).then((res) => {
        this.versionList = res.book
      })
    },
    versionChange() {
      this.form.knowledgePoints = []
      this.knowledgePointsList = []
      this.form.classify = null
      this.classifyList = []
      this.getKnowledgePointsList()
      this.getCategoryList()
    },
    // 获取教材版本列表
    getKnowledgePointsList() {
      let params = {
        id: this.form.version,
      }
      this.$service.getKPTree(params).then((res) => {
        this.knowledgePointsList = res.tree
      })
    },
    // 获取教材试题分类列表
    getCategoryList() {
      let params = {
        bookId: this.form.version,
      }
      this.$service.getCategoryList(params).then((res) => {
        this.classifyList = res.list
      })
    },
  },
  mounted() {
    this.initForm()
    this.getUserList()
  },
}
</script>
<style lang="scss" scoped>
.search-bar {
  padding-top: 24px;
  padding-bottom: 24px;
  color: #979797;
  .el-form-item {
    padding: 0 32px;
  }
  .el-select {
    width: 190px;
  }

  ::v-deep .el-form-item__label {
    color: #999;
  }
  .el-input {
    width: 115px;
  }
  .inline-style {
    span {
      padding: 0 7px;
    }
    .el-button {
      margin-left: 24px;
    }
  }
  .key-word .el-input {
    width: 180px;
  }

  .divider {
    margin-left: 32px;
    margin-bottom: 18px;
    border-bottom: 1px solid #f0f0f0;
  }
}
</style>
